.post {
    max-width: 700px;
    border: 1px solid lightgray;
    margin-bottom: 45px;
}

.post__image {
    width: 100%;
    object-fit:contain;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
}

.post__text {
    font-weight: normal;
    padding: 10px;
}

.post__header {
    display: flex;
    align-items: center;
    padding: 10px;
}

.post__avatar {
    margin-right: 10px;
}